import React, { useState } from 'react';

import CheckPersonalInfoAgreement from '@/components/CheckPersonalInfoAgreement/CheckPersonalInfoAgreement';
import FormTitle from '@/components/FormTitle/FormTitle';
import { FreeExperienceContainer } from '@/components/kakao/FreeExperience/FreeExperience.styles';
import { IFreeExperience } from '@/components/kakao/FreeExperience/FreeExperience.type';
import { postSlackMessage, validationForm } from '@/hooks/postSlackMessage.hook';
import { putPrivacyData } from '@/hooks/usePrivacyForm.hook';
import { PAGE_TYPE } from '@/shared/utils/common.constants';
import { ENTRY_PAGE_SELECTOR } from '@/shared/utils/ktAiCallManager.constants';
import { SELECT_PRIVACY_TYPE } from '@/shared/utils/privacy/privacy.constants';

const FreeExperience = (props: IFreeExperience): React.ReactElement => {
  const { type } = props;

  const [form, setForm] = useState({ coName: '', coTel: '', coCarrier: '', etcCarrier: '', name: '', gender: '', phone: '', birth: '', federalTaxId: '', email: '' });
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);

  const { mutateAsync: KtPrivacyMutate } = putPrivacyData();
  const { mutateAsync: KtSendMessageMutate } = postSlackMessage(PAGE_TYPE.KT, ENTRY_PAGE_SELECTOR[type]);

  const submit = async () => {
    console.log(SELECT_PRIVACY_TYPE[type]);
    try {
      validationForm(PAGE_TYPE.KT, form, isCheckedPrivacy);
      await KtPrivacyMutate({
        phone: form.phone.replace(/-/g, ''),
        email: form.email,
        privacyType: SELECT_PRIVACY_TYPE[type],
        isCheckedAgreement: isCheckedPrivacy,
      });

      await KtSendMessageMutate(form).then(() => {
        setForm({ coName: '', coTel: '', coCarrier: '', etcCarrier: '', name: '', gender: '', phone: '', birth: '', federalTaxId: '', email: '' });
      });

      // AI통화비서
      // 네이버로그분석 분류 1
      let logType;
      if (SELECT_PRIVACY_TYPE[type] === 'PRIVACY_FOR_KT_AI') {
        logType = 1;
      } else {
        logType = 999;
      }
      window._nasa = window._nasa || {};
      if (window.wcs) {
        window._nasa.cnv = window.wcs.cnv('4', logType);
        window.wcs_do(window._nasa);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FreeExperienceContainer id="form" className="wrap colorBack noFloat">
      <div className="gapDef" />
      <div className="inner form clear-parent form-content-wrap">
        <FormTitle />
        <div className="right">
          <div className="inputWrap required">
            <label htmlFor="coName" className="title-label">
              매장명
            </label>
            <input type="text" id="coName" value={form.coName} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="coTel" className="title-label">
              매장 유선번호
            </label>
            <input type="text" id="coTel" value={form.coTel} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap co-carrier-input-wrap required">
            <label className="title-label">유선번호 통신사</label>
            <div className="form-radio-group">
              <section className="radio-wrap">
                <input type="radio" checked={form.coCarrier === 'KT'} id="kt" value="KT" onChange={(e) => setForm((state) => ({ ...state, coCarrier: e.target.value }))} />
                <label htmlFor="kt">KT</label>
              </section>
              <section className="radio-wrap">
                <input type="radio" checked={form.coCarrier === 'LG'} id="lg" value="LG" onChange={(e) => setForm((state) => ({ ...state, coCarrier: e.target.value }))} />
                <label htmlFor="lg">LG</label>
              </section>
              <section className="radio-wrap">
                <input type="radio" checked={form.coCarrier === 'SK'} id="sk" value="SK" onChange={(e) => setForm((state) => ({ ...state, coCarrier: e.target.value }))} />
                <label htmlFor="sk">SK</label>
              </section>
              <section className="radio-wrap etc-wrap">
                <input type="radio" checked={form.coCarrier === '기타'} id="etc" value="기타" onChange={(e) => setForm((state) => ({ ...state, coCarrier: e.target.value }))} />
                <label htmlFor="etc">기타</label>
                <input
                  className="etc-text-input"
                  type="text"
                  id="etc"
                  placeholder="통신사명 입력"
                  value={form.coCarrier === '기타' ? form.etcCarrier : ''}
                  onChange={(e) => setForm((state) => ({ ...state, etcCarrier: e.target.value }))}
                  disabled={form.coCarrier !== '기타'}
                />
              </section>
            </div>
          </div>
          <div className="inputWrap required">
            <label htmlFor="name" className="title-label">
              대표자 성함
            </label>
            <input type="text" id="name" value={form.name} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label className="title-label">대표자 성별</label>
            <div className="form-radio-group">
              <section className="radio-wrap">
                <input type="radio" checked={form.gender === '남성'} id="man" value="남성" onChange={(e) => setForm((state) => ({ ...state, gender: e.target.value }))} />
                <label htmlFor="man">남성</label>
              </section>
              <section className="radio-wrap">
                <input type="radio" checked={form.gender === '여성'} id="woman" value="여성" onChange={(e) => setForm((state) => ({ ...state, gender: e.target.value }))} />
                <label htmlFor="woman">여성</label>
              </section>
            </div>
          </div>
          <div className="inputWrap required">
            <label htmlFor="phone" className="title-label">
              휴대폰 번호
            </label>
            <input type="tel" id="phone" value={form.phone} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="birth" className="title-label">
              대표자 생년월일
            </label>
            <input
              placeholder="예) 900317"
              type="text"
              id="birth"
              value={form.birth}
              onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('1', '')]: e.target.value }))}
            />
          </div>
          <div className="inputWrap required">
            <label htmlFor="federalTaxId" className="title-label">
              사업자 등록번호
            </label>
            <input type="text" id="federalTaxId" value={form.federalTaxId} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap">
            <label htmlFor="email" className="title-label">
              이메일 주소
            </label>
            <input type="email" id="email" value={form.email} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <CheckPersonalInfoAgreement type={type} htmlFor="ktAiForm" setChecked={setIsCheckedPrivacy} />
          <div className="btnBox">
            <button className="defBtn" onClick={submit}>
              신청하기
            </button>
            <p>신청서를 제출하시면 영업일 기준 2일 이내 고객센터를 통해 연락을 드릴 예정입니다.</p>
          </div>
        </div>
      </div>
      <div className="gapDef" />
    </FreeExperienceContainer>
  );
};

export default FreeExperience;
